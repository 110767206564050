import "./App.css";
import portada1 from "./assets/images/portada1.png";
import { Helmet } from "react-helmet";

function App() {
  return (
    <main>
      <Helmet>
        <title>Dolors de Gual</title>
        <meta name="description" content="Página web de Dolors de Gual" />
      </Helmet>
      <h1 class="overflow-hidden" style={{ height: "50vh" }}>
        <img src={portada1} alt="Dolors de Gual"></img>
      </h1>
      <h2 className=" text-center py-4 md:py-10 lg:py-24 xl:py-32 xl:px-96">
        <h3 className="uppercase text-2xl font-semibold tracking-widest">
          Estamos trabajando para que esta página web esté disponible
          próximamente
        </h3>
        <h4 className="pt-2 font-extralight">
          Mientras tanto, puedes ponerte en contacto a través del e-mail:
        </h4>
        <h5 className="text-secondary pt-4">
          <a href="mailto:info@dolorsdegual.com">info@dolorsdegual.com</a>
        </h5>
        <h6 className="uppercase tracking-widest font-light pt-1">
          Disculpa las molestias.
        </h6>
      </h2>
      <h2
        className="p-10 bg-secondary text-white font-semibold text-xs text-center"
        style={{ backgroundColor: "#343a40" }}
      >
        COPYRIGHT DOLORSDEGUAL.COM © 2011. TODOS LOS DERECHOS RESERVADOS | AVISO
        LEGAL
      </h2>
    </main>
  );
}

export default App;
